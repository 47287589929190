import React, { useRef, useEffect } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import styled from 'styled-components';
import itsec1 from '../assets/images/sec1.jpg';
import itsec2 from '../assets/images/sec2.jpg';
import itsec3 from '../assets/images/sec3.jpg';
import itsec4 from '../assets/images/sec4.jpg';
import itsec5 from '../assets/images/sec5.jpg';
import itsec6 from '../assets/images/sec6.jpg';
import itsec7 from '../assets/images/sec7.jpg';
import itsec8 from '../assets/images/sec8.jpg';
import itsec9 from '../assets/images/sec9.jpg';
import itsec10 from '../assets/images/sec10.jpg';



const sectors = [
  { id: 1, name: 'Technology', image: itsec1 },
  { id: 2, name: 'Aviation', image: itsec2 },
  { id: 3, name: 'Banking', image: itsec3 },
  { id: 4, name: 'Utility & Power', image: itsec4 },
  { id: 5, name: 'Human Resource', image: itsec5 },
  { id: 6, name: 'Internet of Things', image: itsec6 },
  { id: 7, name: 'Healthcare', image: itsec7 },
  { id: 8, name: 'Transport', image: itsec8 },
  { id: 9, name: 'Construction & Infrastructure', image: itsec9 },
  { id: 10, name: 'Oil & Gas', image: itsec10 },
];

const Embla = styled.div`
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  margin: auto;
`;

const EmblaContainer = styled.div`
  display: flex;
`;

const EmblaSlide = styled.div`
  position: relative;
  min-width: 24%; 
  padding: 0;
  height:27vw;
  overflow:hidden;
  margin:0.6%;

  @media screen and (max-width : 600px) {
    min-width: 48%;
    margin: 1%;
    height: 55vw;
  }
`;

const EmblaSlideImg = styled.img`
  width: 100%;
  object-fit: cover;
`;

const EmblaSlideCaption = styled.div`
  position: absolute;
  background-color: #fdb73e;
  color: #fff;
  padding: 0.5vw 1vw;
  font-family:cdr;
  font-size: 1.2vw;
  z-index:2;
bottom:10%;
color:black;
  width:fit-content;

  
  @media screen and (max-width : 600px) {
   font-size: 4vw;
  }
`;

export default function SectorCarousel() {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    align: 'start',
  });

  const intervalRef = useRef(null);

  useEffect(() => {
    if (!emblaApi) return;

    const scrollInterval = 5000; // Interval in milliseconds (3 seconds)
    
    const autoScroll = () => {
      if (emblaApi && emblaApi.scrollNext) {
        emblaApi.scrollNext();
      }
    };

    intervalRef.current = setInterval(autoScroll, scrollInterval);

    return () => clearInterval(intervalRef.current); 
  }, [emblaApi]);

  return (
    <Embla ref={emblaRef}>
      <EmblaContainer>
        {sectors.map((sector) => (
          <EmblaSlide key={sector.id}>
            <EmblaSlideImg src={sector.image} alt={sector.name} />
            <EmblaSlideCaption>{sector.name}</EmblaSlideCaption>
          </EmblaSlide>
        ))}
      </EmblaContainer>
    </Embla>
  );
}
