import React, { useState } from 'react';
import styled from 'styled-components';
import './style.css';
import training1 from '../assets/images/trainings/training1.jpg'
import training2 from '../assets/images/trainings/training2.jpg'
import training3 from '../assets/images/trainings/training3.jpg'
import training4 from '../assets/images/trainings/training4.jpg'
import training5 from '../assets/images/trainings/training5.jpg'

const TrainingCont = styled.div`
  width: 100%;
  height: 70vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  & h2 {
    color: white;
    font-size: 3vw;
    margin-top: 2vw;
    font-family: cdl;
    text-transform: uppercase;
  }

  & h2 span {
    color: #fdb73e;
    font-family:cdb;
  }

  & .container {
      width: 80%;
    margin-top: 2vw;
    padding: 2vw;
    background-color: #333;
    color: white;
    opacity: 0;
    transform: scale(0.95);
    transition: opacity 0.5s ease, transform 0.5s ease;
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
    position:absolute;
    margin-top:14vw;
  }
  
  & .training-box{
  width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  height:15vw;
  }

 
  & .container.show {
    opacity: 1;
    transform: scale(1);
  }
  
  & .training-left{
  width:50%;
  height:10vw;
  display:flex;
  align-items:center;
  gap:1vw;
  }

  & .training-part{
     height:10vw;
     display:flex;
     flex-direction:column;
     justify-content:center;
   }

   & .training-left .date{
   font-size:1.6vw;
   font-family:cdl;
   height:10vw;
   display:flex;
   flex-direction:column;
   line-height:0vw;
   margin-top:1vw;
   }

    & .training-left .t-topic{
   font-size:1.6vw;
   font-family:cdr;
   }

    & .training-left .t-duration{
    font-family:source sans 3;
    font-weight:400;
    font-size:0.9vw;
    }

     & .training-left .t-button{
     width:fit-content;
     padding:0.3vw 0.8vw;
     margin-top:0.5vw;
     background:#fdb73e;
     border:none;
     font-size:0.9vw;
     }

  & .training-left .date span{
   font-size:7vw;
   font-family:cdm;
   line-height:7vw;
   color:#fdb73e;
    }
   
   & .training-right{
  width:50%;
  height:10vw;
  display:flex;
  align-items:center;
  justify-content:center;
  }

  & .training-img-box{
  width:50%;
  height:14vw;
  overflow:hidden;
  display:flex;
  align-items:center;
  justify-content:center;
  position:relative;
  }

  & .training-img-box img{
  width:100%;
  }

  & .training-short-over{
  width:50%;
  }
  & .training-short-over p {
    margin:0vw;
    padding:0vw 1vw;
    text-align:justify;
    font-size:.9vw;
  }

  & .training-short-over h3{
      padding:0vw 1vw;
  }





  & .button-group {
    margin-top: 2vw;
  }

  & .button-group button {
    margin: 0 1vw;
    width:15vw;
    padding: 0.8vw 1vw;
    font-size: 1.5vw;
    cursor: pointer;
    background:none;
    color:white;
    border:1px solid #fdb73e;
    font-family:cdl;
    font-size:1.1vw;
    text-transform:uppercase;
  }

  & .button-group .active{
  background:#fdb73e;
  color:black;
  font-family:cdr;
  }
`;

export default function Trainings() {
  const [showFirst, setShowFirst] = useState(true);

  return (
    <TrainingCont>
      <h2>Upcoming <span>Trainings</span></h2>

      <div className="button-group">
      <button
        className={`button ${showFirst ? 'active' : ''}`}
        onClick={() => setShowFirst(true)}
      >
        Technical
      </button>
      <button
        className={`button ${!showFirst ? 'active' : ''}`}
        onClick={() => setShowFirst(false)}
      >
        Non - Technical
      </button>
      </div>

      <div className={`container ${showFirst ? 'show' : ''}`}>
       <div className="training-box">
        <div className="training-left">
          <div className="date">
            <span>06</span> <br />
            July
          </div>
          <div className="training-part">
            <div className="t-topic"> Certified maintenance and reliability professional  </div>
            <div className="t-duration">Duration : 5 Days</div>
            <button className='t-button'>Enquire Now</button> 
          </div>
        </div>
        <div className="training-right">
<div className="training-img-box">
<img src={training1} alt="" />
</div>
<div className="training-short-over">
  <h3>About</h3>
<p>
 Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia blanditiis eos molestiae debitis odio perferendis quae, saepe dolorem illo laborum recusandae ea excepturi porro non. Facilis deserunt repellendus at, eum hic natus ad, inventore esse porro iusto tempore nihil. Consectetur..........
 </p>
</div>
        </div>
       </div>
       <div className="training-box">
        <div className="training-left">
          <div className="date">
            <span>20</span> <br />
            August
          </div>
          <div className="training-part">
            <div className="t-topic"> Certified maintenance and reliability professional  </div>
            <div className="t-duration">Duration : 5 Days</div>
            <button className='t-button'>Enquire Now</button> 
          </div>
        </div>
        <div className="training-right">
        <div className="training-short-over">
  <h3>About</h3>
<p>
 Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia blanditiis eos molestiae debitis odio perferendis quae, saepe dolorem illo laborum recusandae ea excepturi porro non. Facilis deserunt repellendus at, eum hic natus ad, inventore esse porro iusto tempore nihil. Consectetur..........
 </p>
</div>
<div className="training-img-box">
<img src={training2} alt="" />
</div>
        </div>
       </div>
       <div className="training-box">
        <div className="training-left">
          <div className="date">
            <span>07</span> <br />
            October
          </div>
          <div className="training-part">
            <div className="t-topic"> Certified maintenance and reliability professional  </div>
            <div className="t-duration">Duration : 5 Days</div>
            <button className='t-button'>Enquire Now</button> 
          </div>
        </div>
        <div className="training-right">
<div className="training-img-box">
<img src={training4} alt="" />
</div>
<div className="training-short-over">
  <h3>About</h3>
<p>
 Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia blanditiis eos molestiae debitis odio perferendis quae, saepe dolorem illo laborum recusandae ea excepturi porro non. Facilis deserunt repellendus at, eum hic natus ad, inventore esse porro iusto tempore nihil. Consectetur..........
 </p>
</div>
        </div>
       </div>
      </div>

      <div className={`container ${!showFirst ? 'show' : ''}`}>
      <div className="training-box">
        <div className="training-left">
          <div className="date">
            <span>06</span> <br />
            July
          </div>
          <div className="training-part">
            <div className="t-topic"> Certified maintenance and reliability professional  </div>
            <div className="t-duration">Duration : 5 Days</div>
            <button className='t-button'>Enquire Now</button> 
          </div>
        </div>
        <div className="training-right">
<div className="training-img-box">
<img src={training3} alt="" />
</div>
<div className="training-short-over">
  <h3>About</h3>
<p>
 Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia blanditiis eos molestiae debitis odio perferendis quae, saepe dolorem illo laborum recusandae ea excepturi porro non. Facilis deserunt repellendus at, eum hic natus ad, inventore esse porro iusto tempore nihil. Consectetur..........
 </p>
</div>
        </div>
       </div>
       <div className="training-box">
        <div className="training-left">
          <div className="date">
            <span>20</span> <br />
            August
          </div>
          <div className="training-part">
            <div className="t-topic"> Certified maintenance and reliability professional  </div>
            <div className="t-duration">Duration : 5 Days</div>
            <button className='t-button'>Enquire Now</button> 
          </div>
        </div>
        <div className="training-right">
        <div className="training-short-over">
  <h3>About</h3>
<p>
 Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia blanditiis eos molestiae debitis odio perferendis quae, saepe dolorem illo laborum recusandae ea excepturi porro non. Facilis deserunt repellendus at, eum hic natus ad, inventore esse porro iusto tempore nihil. Consectetur..........
 </p>
</div>
<div className="training-img-box">
<img src={training5} alt="" />
</div>
        </div>
       </div>
       <div className="training-box">
        <div className="training-left">
          <div className="date">
            <span>07</span> <br />
            October
          </div>
          <div className="training-part">
            <div className="t-topic"> Certified maintenance and reliability professional  </div>
            <div className="t-duration">Duration : 5 Days</div>
            <button className='t-button'>Enquire Now</button> 
          </div>
        </div>
        <div className="training-right">
<div className="training-img-box">
<img src={training1} alt="" />
</div>
<div className="training-short-over">
  <h3>About</h3>
<p>
 Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia blanditiis eos molestiae debitis odio perferendis quae, saepe dolorem illo laborum recusandae ea excepturi porro non. Facilis deserunt repellendus at, eum hic natus ad, inventore esse porro iusto tempore nihil. Consectetur..........
 </p>
</div>
        </div>
       </div>
      </div>

      
    </TrainingCont>
  );
}
