import React from "react";
import styled from "styled-components";
import tl from "../assets/images/tr.png";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import g1 from "../assets/images/gallery/g1.jpg";
import g2 from "../assets/images/gallery/g2.jpg";
import g3 from "../assets/images/gallery/g3.jpg";
import g4 from "../assets/images/gallery/g4.jpg";
import g5 from "../assets/images/gallery/g5.jpg";
import g6 from "../assets/images/gallery/g6.jpg";
import g7 from "../assets/images/gallery/g7.jpg";
import g8 from "../assets/images/gallery/g8.jpg";
import g9 from "../assets/images/gallery/g9.jpg";
import g10 from "../assets/images/gallery/g10.jpg";
import g11 from "../assets/images/gallery/g11.jpg";
import g12 from "../assets/images/gallery/g12.jpg";
import g13 from "../assets/images/gallery/g13.jpg";
import g14 from "../assets/images/gallery/g14.jpg";
import g15 from "../assets/images/gallery/g15.jpg";
import g16 from "../assets/images/gallery/g16.jpg";
import g17 from "../assets/images/gallery/g17.jpg";


const Gallery = () => {

  const images = [
    g1,g2,g3,g4,g5,g6,g7
  ];


  // const images = [
  //   "../assets/images/gallery/g1.jpg", "../assets/images/gallery/g2.jpg", "../assets/images/gallery/g3.jpg",
  //   "../assets/images/gallery/g4.jpg", "../assets/images/gallery/g5.jpg", "../assets/images/gallery/g6.jpg",
  //   "../assets/images/gallery/g7.jpg", "../assets/images/gallery/g8.jpg", "../assets/images/gallery/g9.jpg",
  //   "../assets/images/gallery/g10.jpg", "../assets/images/gallery/g11.jpg", "../assets/images/gallery/g12.jpg",
  //   "../assets/images/gallery/g13.jpg", "../assets/images/gallery/g14.jpg", "../assets/images/gallery/g15.jpg",
  //   "../assets/images/gallery/g16.jpg", "../assets/images/gallery/g17.jpg"
  // ];

  const [popupImage, setPopupImage] = useState(null); // State for popup image
  const [isPopupOpen, setPopupOpen] = useState(false); // State for controlling popup visibility
  const [flippedBoxes, setFlippedBoxes] = useState([]); // State for keeping track of flipped boxes

  // Handle flipping boxes
  const flipBoxes = () => {
    const shuffledIndices = Array.from({ length: images.length }, (_, i) => i).sort(() => 0.5 - Math.random()).slice(0, 3);
    setFlippedBoxes(shuffledIndices);
  };

  // Open popup with image
  const handleBoxClick = (image) => {
    setPopupImage(image);
    setPopupOpen(true);
  };

  // Close popup
  const closePopup = () => {
    setPopupOpen(false);
  };

  // On component mount, set interval for flipping boxes
  useEffect(() => {
    flipBoxes(); // Initial flip
    const intervalId = setInterval(flipBoxes, 1500); // Flip every 1.5 seconds

    return () => clearInterval(intervalId); // Clean up interval on unmount
  }, []);

  return (
    <div class="gallery-section section" data-aos="fade-up" id="gallery">
        <div class="gallery-glow">

        </div>
        <div class="title"><span>OUR</span> GALLERY</div>
        <div class="gallery">
            
            <div class="box" id="box1">
            <img src={g1}  />
                <div class="galplus"><i class="fa-solid fa-plus"></i></div>
            </div>
            <div class="box" id="box2">
            <img src={g2}  />
                <div class="galplus"><i class="fa-solid fa-plus"></i></div>
            </div>
            <div class="box" id="box3">
                 <img src={g3}  />
                <div class="galplus"><i class="fa-solid fa-plus"></i></div>
            </div>
            <div class="box" id="box4">
                 <img src={g4}  />
                <div class="galplus"><i class="fa-solid fa-plus"></i></div>
            </div>
            <div class="box" id="box5">
                 <img src={g5}  />
                <div class="galplus"><i class="fa-solid fa-plus"></i></div>
            </div>
            <div class="box" id="box6">
                 <img src={g6}  />
                <div class="galplus"><i class="fa-solid fa-plus"></i></div>
            </div>
            <div class="box" id="box7">
                 <img src={g7}  />
                <div class="galplus"><i class="fa-solid fa-plus"></i></div>
            </div>
            <div class="box" id="box8">
                 <img src={g8}  />
                <div class="galplus"><i class="fa-solid fa-plus"></i></div>
            </div>
            <div class="box" id="box9">
                 <img src={g9}  />
                <div class="galplus"><i class="fa-solid fa-plus"></i></div>
            </div>
            <div class="box" id="box10">
                 <img src={g10}  />
                <div class="galplus"><i class="fa-solid fa-plus"></i></div>
            </div>
            <div class="box" id="box11">
                 <img src={g11}  />
                <div class="galplus"><i class="fa-solid fa-plus"></i></div>
            </div>
            <div class="box" id="box12">
                 <img src={g12}  />
                <div class="galplus"><i class="fa-solid fa-plus"></i></div>
            </div>
            <div class="box" id="box13">
                 <img src={g13}  />
                <div class="galplus"><i class="fa-solid fa-plus"></i></div>
            </div>
            <div class="box" id="box14">
                 <img src={g14}  />
                <div class="galplus"><i class="fa-solid fa-plus"></i></div>
            </div>
            <div class="box" id="box15">
                 <img src={g15}  />
                <div class="galplus"><i class="fa-solid fa-plus"></i></div>
            </div>
            <div class="box" id="box16">
                 <img src={g16}  />
                <div class="galplus"><i class="fa-solid fa-plus"></i></div>
            </div>
            <div class="box" id="box17">
                 <img src={g7}  />
                <div class="galplus"><i class="fa-solid fa-plus"></i></div>
            </div>
        </div>

        
        <div id="popup" class="popup">
            <span class="popup-close">&times;</span>
            <img class="popup-img" src="" alt="Popup Image"/>
        </div>
    </div>
  );
};

export default Gallery;