import React from 'react'
import Trainings from './Trainings'
import styled from 'styled-components'
import ttop from '../assets/images/training-top.jpg';

const TrainingTop = styled.div`
width:100%;
height:15vw;
background-image:url(${ttop});

& .ttpatch{
  width: 100%;
  height: 100%;
  background: linear-gradient(transparent,black);
  display: flex;
  align-items: center;
  justify-content: center;
}

& .ttpatch h2{
  color:#fdb73e;
  font-family: "cdb";
  width: 80%;
  font-size: 3vw;
}
`;

export default function TrainingPage() {
  return (
    <div>
    <TrainingTop>
      <div className="ttpatch">
<h2>TRAININGS</h2>
      </div>
    </TrainingTop>
    <Trainings />
    </div>
  )
}
