import React from "react";
import styled from "styled-components";
import "./style.css";
import topimg from "../assets/images/about-top.jpg";
import mainimg from "../assets/images/overmain.jpg";
// import { PiVideoConferenceLight } from "react-icons/pi";
// import { RiTeamFill } from "react-icons/ri";
// import { GiUnlocking } from "react-icons/gi";
// import { SiSession } from "react-icons/si";
import vision1 from "../assets/images/vision1.jpg";
import vision2 from "../assets/images/vision2.jpg";
import wwd from "../assets/images/wwd.png";

const AboutTop = styled.div`
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(${topimg});
  background-size: cover;
  background-position: 100%;
  position: relative;




  & div {
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(transparent, black);
  }

  & h1 {
    color: white;
    font-size: 4vw;
    font-family: cdl;
    line-height: 3.4vw;
    position: relative;
    z-index: 2;
    margin-left: 10%;
  }

  & h1 span {
    color: #fdb73e;
    font-family: cdb;
  }
  & h3 {
    text-transform: uppercase;
    color: white;
    font-weight: 400;
    z-index: 2;
    margin-left: 11%;
  }

  @media screen and (max-width:600px) {
    height: 30vh;

    & h1{
      font-size: 10vw;
      line-height: 10vw;
    }
  }
  

`;
const AboutContainer=styled.div`
width: 100%;
height: auto;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 3vw 0vw;

& h2{
  color:white;
  text-transform: uppercase;
  font-size: 2.5vw;
  font-family: "cdl";
}

& h2 span{
  color: #fdb73e;
font-family: "cdb";
}

& p{
  color:1vw;
  color:white;
  text-align: center;
  width: 80%;
  font-family: "Source Sans 3";
  font-size: 1vw;
  line-height: 1.7;
  font-weight: 300;
}
@media screen and (max-width:600px){
  & h2{
    font-size: 6vw;
  }
  & p{
    font-size: 4vw;
  }
}
`
const AboutMain = styled.div`
  width: 100%;
  height: 49vw;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width:600px){
    height: auto;
  }
`;

const AboutCont = styled.div`
  width: 80%;
  height: 49vw;
  position: relative;
  @media screen and (max-width:600px){
    height: 200vw;
    width: 90%;
  }
`;

const AmPatch = styled.div`
  width: 10%;
  height: 10vw;
  position: absolute;
  z-index: -1;
  left: 0vw;
  top: 2vw;
  background-color: #fdb73e;
`;

const AboutLeft = styled.div`
  width: 50%;
  height: 49vw;
  display: flex;
  align-items: center;

  & .alpatch {
    width: 68%;
    height: 35vw;
    background-image: url(${mainimg});
    background-color: #fdb73e;
    background-size: cover;
    margin-left: 2.5vw;
    margin-top: -4vw;
  }

  & .left-cont {
    width: 60%;
    position: absolute;
    height: 40vw;
    left: 21.5%;
    z-index: 2;
  }
  & h2 {
    color: white;
    font-family: cdl;
    color: black;
    font-size: 3vw;
    margin-top: 2.5vw;
    text-transform: uppercase;
    margin-left: 6vw;
  }

  & h2 span {
    color: #fdb73e;
    font-family: "cdb";
    letter-spacing: 0.1vw;
  }

  & p {
    color: white;
    width: 95%;
    margin-left: 33%;
    font-size: 1.2vw;
    line-height: 1.8vw;
    font-weight: 300;
    margin-top: 0vw;
  }
  & .ab-topics {
    width: 95%;
    margin-left: 33%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  & .ab-topic {
    width: 50%;
    margin: 1vw 0vw;
    display: flex;
    align-items: center;
    gap: 1vw;
    color: white;
    font-family: source sans 3;
    font-size: 0.9vw;
  }

  & .ab-icon {
    padding: 0.5vw;
    color: black;
    background-color: #fdb73e;
  }

  & .ab-images {
    display: flex;
    gap: 1vw;
    margin-left: 50%;
    width: 100%;
    position: absolute;
    margin-top: 8.3vw;
  }

  & .ab-img-box {
    width: 20vw;
    height: 12vw;
    overflow: hidden;
  }
  & .aib2 {
    width: 13vw;
  }
  & .ab-img-box img {
    width: 110%;
    filter: saturate(0);
  }
  & .aib2 img {
    width: 150%;
    filter: none;
  }

  @media screen and (max-width:600px){
    width: 100%;
    height: auto;
    padding:10vw 0;

    & .left-cont{
      width: 70%;
      height: auto;
      margin-top: 40vw;
    }

    & .alpatch{
      height: 80vw;
    }

    & h2{
      font-size: 17vw;
    }

    & p{
      color: white;
    width: 95%;
    margin-left: 17%;
    font-size: 4.2vw;
    line-height: 1.7;
    margin-top: 0vw;
    }
    & .ab-images {
    display: flex;
    gap: 1vw;
    margin-left: 10%;
    }
    & .ab-img-box {
    width: 40vw;
    height: 30vw;
}
& .aib2 {
    width: 30vw;
}
  }
`;

const AbDotbox = styled.div`
  width: 14vw;
  height: 14vw;
  position: absolute;
  z-index: -1;
  left: 25%;
  bottom: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  & .dot-box {
    width: 2vw;
    height: 2vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .abdot {
    width: 0.7vw;
    height: 0.7vw;
    background-color: #fdb73e;
    border-radius: 50%;
  }

    @media screen and (max-width:600px){
      width: 50vw;
      height: 50vw;
      left: 2%;
      bottom: 27vw;


      & .dot-box {
    width: 7vw;
    height: 7vw;

    }

    & .abdot {
    width: 1vw;
    height: 1vw;
    }
  }
`;

const Story = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3vw;
  padding-bottom: 3vw;

  & .story-cont {
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & h2 {
    color: white;
    font-family: cdl;
    font-size: 3vw;
    text-transform: uppercase;
  }

  & h2 span {
    color: #fdb73e;
    font-family: "cdb";
    letter-spacing: 0.1vw;
  }

  & p {
  color: white;
    width: 60%;
    text-align: center;
    font-family: source sans 3;
    line-height: 1.5vw;
    font-size: 1.1vw;
    font-weight: 300;
  }
  & .fpara::before {
    content: open-quote;
    color: white;
    display: block;
    position: absolute;
    z-index: 2;
    font-size: 7vw;
    font-family: cdb;
    margin-left: -4vw;
    opacity: 0.5;
  }
  & .lpara::after {
    content: close-quote;
    color: white;
    display: block;
    position: absolute;
    z-index: 2;
    font-size: 7vw;
    font-family: cdb;
    margin-left: 47vw;
    margin-top: 1vw;
    opacity: 0.5;
  }

  @media screen and (max-width:600px){
    & h2 {
    font-size: 7vw;
}

& p {
    width: 90%;
    text-align: center;
    line-height: 1.7;
    font-size: 4.1vw;
}
& .fpara::before {
    font-size: 20vw;
    margin-left: -11vw;
    margin-top: -12vw;
}

& .lpara::after {
    z-index: 2;
    font-size: 20vw;
    margin-left: 70vw;
    margin-top: -23vw;
}
  }
`;

const WhyUs = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3vw 0vw;

  & .why-us-cont {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & h2 {
    color: white;
    font-size: 3vw;
    font-family: "cdl";
  }

  & h2 span {
    color: #fdb73e;
    font-family: "cdb";
    letter-spacing: 0.1vw;
  }

  & .why-us-boxes {
    width: 100%;
    min-height: 10vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2vw;
  }

  & .why-us {
    width: 23%;
    height: 20vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  & .wu2 {
    background: linear-gradient(black, #fdb73e);
  }

  & .why-us h3 {
    color: white;
    font-family: "cdr";
    letter-spacing: 0.1vw;
    text-transform: uppercase;
  }

  & .why-us p {
    color: white;
    text-align: center;
    padding: 0vw 2vw;
    font-size: 0.9vw;
    line-height: 1.4vw;
  }
`;

const WeDo = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 30vw;
  padding: 8vw 0vw;

  & .we-do {
    width: 80%;
    height: auto;
    display: flex;
    align-items: center;
  }

  & .wedo-left {
    width: 60%;
    height: auto;
  }

  & h2 {
    color: white;
    font-family: "cdl";
    font-size: 3vw;
  }
  & h2 span {
    color: white;
    font-family: "cdb";
    letter-spacing: 0.02vw;
    color: #fdb73e;
  }
  & p {
     color: white;
    font-size: 1.2vw;
    line-height: 1.7vw;
    font-weight: 300;
  }

  & .wedo-img {
    width: 30vw;
    height: 35vw;
    background: #fdb73e;
    margin-left: 10vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
  }

  & img {
    width: 33.25vw;
    margin-left: -15.8vw;
    margin-top: 0.05vw;
  }

  & .wedo-impatch {
    width: 25vw;
    height: 30vw;
    background-color: black;
    position: absolute;
    z-index: -1;   
   
  }

  @media screen and (max-width:600px) {
    


    & .we-do{
      flex-direction: column;
      width: 90%;
    }

    & .wedo-left {
    width: 90%;
}

& h2 {
    font-size: 7vw;
}

& p {
    color: white;
    font-size: 4.2vw;
    line-height: 1.7;

}
& .wedo-img {
    width: 60vw;
    height: 80vw;
}
& .wedo-impatch {
    width: 54vw;
    height: 74vw;
}
& img {
        width: 75.75vw;
        margin-left: -27.8vw;
        margin-top: 0.35vw;
    }
   }
`;

const AbDotboxw = styled.div`
  width: 20vw;
  height: 10vw;
  position: absolute;
  z-index: -1;
  left: -13vw;
  bottom: -2.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  & .dot-box {
    width: 2vw;
    height: 2vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .abdot {
    width: 0.7vw;
    height: 0.7vw;
    background-color: #fdb73e;
    border-radius: 50%;
  }
`;

export default function About() {
  return (
    <div>
      <AboutTop>
        <div></div>
        <h3>Get to know</h3>
        <h1>
          <span>ABOUT</span> US
        </h1>
      </AboutTop>
      <AboutContainer>
        <h2>Trainings & <span>Workshops</span> </h2>
        <p>
    
We also make leadership development accessible to those who aspire to have a better future by bridging traditional management with global values. We know how to relate at all organization and staff levels to improve productivity, culture, and work relationships. We have assisted organizations to improve their PRODUCTIVITY THROUGH TRAINING ASPECT IN PLACE and individuals to add greater value by learning to apply new skills and knowledge. Capability building is essentially intended to transform an individual employees' competence into organizational strengths. The competency gaps are either identified for current business strategies or future product/ technology road map that calls for excellence in niche competence differentiators to the organization’s success.
<br />
<br />
Trinity Global has etched & carved a niche in the area of capability building cutting across industries and functional areas. With a comprehensive and robust spread of offerings through workshops and sessions, Trinity Global out to be an ever-reliable knowledge partner creating differentiation through interventions and also measuring effectiveness to measure the true business transformation pre and post the entire capability building process. As the preferred global provider of business trainings and workshops Trinity Global strives constantly to understand the organizations global project requirements, expansion plans and tailors cutting edge training courses and workshops to cater to the requirements of global brands to ensure that together we meet our business objectives.

        </p>
      </AboutContainer>
      <AboutMain>
        <AboutCont>
          <AmPatch></AmPatch>
          <AboutLeft>
            <div className="alpatch"></div>
            <div className="left-cont">
              <h2>
                Our <span>Vision</span>
              </h2>
              <p>
                "To be the premier provider of innovative training solutions
                that empower individuals and organizations to unlock their full
                potential and thrive in an ever-evolving world.Our modules will
                encourage you to take on the biggest challenges and the tenacity
                to see them through.
              </p>
              {/* <div className="ab-topics">
                <div className="ab-topic">
                  <PiVideoConferenceLight className="ab-icon" /> Empower through
                  events
                </div>
                <div className="ab-topic">
                  <RiTeamFill className="ab-icon" /> Inspire, engage, transform
                </div>
                <div className="ab-topic">
                  <GiUnlocking className="ab-icon" /> Unlocking potential daily
                </div>
                <div className="ab-topic">
                  <SiSession className="ab-icon" /> Elevate with every session
                </div>
              </div> */}
              <div className="ab-images">
                <div className="ab-img-box">
                  <img src={vision1} alt="training classroom" />
                </div>
                <div className="ab-img-box aib2">
                  <img src={vision2} alt="training classroom" />
                </div>
              </div>
            </div>
          </AboutLeft>

          <AbDotbox>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
            <div className="dot-box">
              <div className="abdot"></div>
            </div>
          </AbDotbox>
        </AboutCont>
      </AboutMain>
      <Story>
        <div className="story-cont">
          <h2>
            Our <span>Mission</span>{" "}
          </h2>
          <p className="fpara">
            Our mission is to equip learners with the skills and knowledge they
            need to thrive in today’s fast-paced environment. We believe that
            continuous learning is the key to personal and professional growth."
          </p>
          <p className="lpara">
          </p>
        </div>
      </Story>
      {/* <WhyUs>
        <div className="why-us-cont">
          <h2>
            WHY <span>CHOOSE US</span>
          </h2>
          <div className="why-us-boxes">
            <div className="why-us">
              <h3>Lorem ipsum dolor sit.</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Molestias adipisci explicabo dolores, velit repudiandae ea
                laboriosam exercitationem asperiores similique rerum id incidunt
                atque natus.
              </p>
            </div>
            <div className="why-us wu2">
              <h3>Lorem ipsum dolor sit.</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Molestias adipisci explicabo dolores, velit repudiandae ea
                laboriosam exercitationem asperiores similique rerum id incidunt
                atque natus.
              </p>
            </div>
            <div className="why-us">
              <h3>Lorem ipsum dolor sit.</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Molestias adipisci explicabo dolores, velit repudiandae ea
                laboriosam exercitationem asperiores similique rerum id incidunt
                atque natus.
              </p>
            </div>
            <div className="why-us wu2">
              <h3>Lorem ipsum dolor sit.</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Molestias adipisci explicabo dolores, velit repudiandae ea
                laboriosam exercitationem asperiores similique rerum id incidunt
                atque natus.
              </p>
            </div>
          </div>
        </div>
      </WhyUs> */}
      <WeDo>
        <div className="we-do">
          <div className="wedo-left">
            <h2>
              IN <span>HOUSE</span> COURSES
            </h2>
            <p>
              Based on your company’s requirement we can develop and deliver
              custom-made training programs to effective tools & skills to your
              employees to achieve enhanced efficiency & effective productivity
              at the location based on your requirements Our customized in-house
              training courses designed & delivered for the specific needs & to
              provide cost effective training to suit company’s strategies and
              training budgets.
            </p>
          </div>
          <div className="wedo-right">
            <div className="wedo-img">
              <img src={wwd} alt="" />
              <div className="wedo-impatch"></div>
              <AbDotboxw>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
                <div className="dot-box">
                  <div className="abdot"></div>
                </div>
              </AbDotboxw>
            </div>
          </div>
        </div>
      </WeDo>
    </div>
  );
}
