// components/PageTransition.js
import React from 'react';
import { motion } from 'framer-motion';

const PageTransition = ({ children }) => (
  <>
    <motion.div
      initial={{ width: '100%' }}  
      animate={{ width: '0%' }}   
      exit={{ width: '100%' }}     
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#fdb73e', 
        zIndex: 20,
        pointerEvents: 'none',
      }}
    />
    {children}
  </>
);

export default PageTransition;
