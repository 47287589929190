import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500); // Delay of 1 second (1000 ms)


    return () => clearTimeout(timer);
  }, [pathname]);

  return null;
}

export default ScrollToTop;
