import React from 'react'
import styled from 'styled-components'
import st from '../assets/images/service-top.jpg';
import ser1 from '../assets/images/ser1.jpg';
import ser2 from '../assets/images/ser2.jpg';
import ser3 from '../assets/images/ser3.jpg';
import ser4 from '../assets/images/ser4.jpg';
import ser5 from '../assets/images/ser5.jpg';

const ServicesTop = styled.div`
width: 100%;
height: 15vw;
background-image: url(${st});

@media screen and (max-width : 600px) {
  height: 50vw;
  
}
`;
const ServiceTopOverlay = styled.div`
width: 100%;
height: 15vw;
background: linear-gradient(rgb(0,0,0,0.3),black);
display: flex;
align-items: center;

& h2{
  color:white;
font-family:"cdl";
font-size: 3vw;
margin-left: 10%;
}

& h2 span{
color:#fdb73e;
font-family: "cdb";
}
@media screen and (max-width : 600px) {
  height: 50vw;

  & h2 {
    font-size: 8vw;
}

& p {
    width: 75%;
    font-size: 4vw;
    line-height: 1.7;
}
  
}
`;

const ServicesMain = styled.div`
width: 100%;
height: auto;
padding: 3vw 0vw;
display: flex;
align-items: center;
justify-content: center;

& p {
  color:white;
  font-family: "Source Sans 3";
  width: 60%;
  text-align: center;
  font-size: 1vw;
  line-height: 1.7;
}

@media screen and (max-width : 600px) {


& p {
    width: 75%;
    font-size: 4vw;
    line-height: 1.7;
}
  
}
`;

const ServiceH = styled.h2`
color:white;
text-align: center;
text-transform: uppercase;
font-family: "cdl";
font-size: 3vw;
padding-bottom: 1vw;

& span{
  color: #fdb73e;
  font-family: "cdb";
}

@media screen and (max-width : 600px) {

font-size: 7vw;
  
}
`;

const ServiceBoxes = styled.div`
width: 100%;
height: auto;
display: flex;
flex-direction: column;
align-items: center;
padding: 2vw 0vw;

& .service-box{
  width: 80%;
  height: 30vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

& .service-box::before{
  content: "";
  width: 0.7vw;
  height: 0.7vw;
  background-color: #fdb73e;
  position: absolute;
  border-radius: 50%;
}

& .service-box::after{
  content: "";
  width: 1px;
  height: 100%;
  background-color: #ffffff2e;
  position: absolute;
}
& .service-cont,.service-img{
  width: 50%;
}

& .service-img{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

& .sr-patch-box{
  width: 90%;
  height: 25vw;
  background-color: #fdb73e;
}
& .sr-img-box{
  width: 87%;
  height: 24vw;
  background-color: #fdb73e;
  position: absolute;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
& .sr-img-box img{
  width: 135%;
  transition: all 0.5s ease;
}
& .sr-img-box:hover img{
margin-left: 2vw;
}
& .service-cont{
  padding-left: 5vw;
}
& .service-cont h3{
  color: white;
  font-size: 1.2vw;
}

& .service-cont p{
  color:white;
  width: 100%;
  font-family: "Source Sans 3";
  font-size: 1vw;
  font-weight: 300;
  width: 80%;
}

& .service-list{
  color:white;

}

& .service-list li{
  font-size: 1vw;
  font-family: "Source Sans 3";
  font-weight: 300;
}

@media screen and (max-width : 600px) {

& .service-box {
    width: 90%;
    height:auto;
  }
  & .service-cont h3 {
    width: 90%;
    font-size: 4vw;
}
& .service-cont p {
    color: white;
    width: 100%;
    font-family: "Source Sans 3";
    font-weight: 300;
    width: 90%;
    font-size: 3.8vw;
}
& .service-list{
  margin: 0;
  padding: 0;
  width: 95%;
  list-style: none;
}
& .service-list li {
    font-size: 3.8vw;
    border-bottom: 1px solid white;
}
& .sr-patch-box {
    width: 90%;
    height: 66vw;

}
& .sr-img-box {
    width: 84%;
    height: 63vw;
}
& .sr-img-box img {
    width: 300%;
    transition: all 0.5s ease;
}
}

`;


export default function Services() {
  return (
    <div>
      <ServicesTop>
<ServiceTopOverlay>
<h2>OUR <span>SERVICES</span></h2>
</ServiceTopOverlay>
      </ServicesTop>
      <ServicesMain>
<p>
  
Trinity Global, we are dedicated to empowering individuals and organizations with world-class training solutions. Our expertise lies in providing tailored, innovative, and comprehensive learning programs that drive personal and professional growth, enhance skills, and foster a culture of continuous improvement.

</p>
      </ServicesMain>
      <ServiceH>
      What <span>We Offer</span> ? 
      </ServiceH>
      <ServiceBoxes>
       <div className="service-box">
        <div className="service-cont">
<h3>1. Corporate Training Programs</h3>
<p>
We design and deliver specialized corporate training that helps businesses upskill their workforce and stay competitive. Whether it’s leadership development, technical skills, or soft skills training, we offer a broad range of programs that meet the evolving needs of today's workplace
</p>
<ul className="service-list">
  <li>Leadership & Management</li>
  <li>Sales & Customer Service Training</li>
  <li>Team Building & Collaboration</li>
  <li>Digital Transformation Skills</li>
  <li>Compliance & Regulatory Training</li>
</ul>
        </div>
        <div className="service-img">
<div className="sr-patch-box">

</div>
<div className="sr-img-box">
<img src={ser1} alt="" />
</div>
        </div>
       </div>
       <div className="service-box">
       <div className="service-img">
          <div className="sr-patch-box">

          </div>
          <div className="sr-img-box">
          <img src={ser2} alt="" />
</div>
          </div>
        <div className="service-cont">
        <h3>2. E-Learning Solutions</h3>
<p>
Our cutting-edge e-learning platforms allow for flexible, on-demand learning that fits your schedule. With a library of interactive, multimedia-rich content, learners can access modules anytime, anywhere, on any device. Our e-learning solutions are designed to enhance knowledge retention and ensure measurable results.
</p>
<ul className="service-list">
  <li>Custom E-Learning Development</li>
  <li>Mobile Learning Solutions</li>
  <li>Interactive Learning Modules</li>
  <li>Gamification</li>
  <li>Learning Management Systems (LMS)</li>
</ul>
        </div>
        
       </div>
       <div className="service-box">
       <div className="service-cont">
        <h3>3. Instructor-Led Training (ILT)</h3>
<p>
Our instructor-led training programs offer a more personalized and immersive learning experience, with expert trainers guiding your team through hands-on exercises, case studies, and group discussions. Whether onsite or virtually, we ensure a highly engaging and interactive learning environment.
</p>
<ul className="service-list">
  <li>Onsite Workshops</li>
  <li>Virtual Classroom Training</li>
  <li>Blended Learning Options</li>
  <li>Custom ILT Programs</li>
</ul>
        </div>
       <div className="service-img">
<div className="sr-patch-box">

</div>
<div className="sr-img-box">
<img src={ser3} alt="" />
</div>
</div>
      
       
       </div>
       <div className="service-box">
       <div className="service-img">
<div className="sr-patch-box">

</div>
<div className="sr-img-box">
<img src={ser4} alt="" />
</div>
</div>
        <div className="service-cont">
        <h3>4. Consulting & Advisory Services</h3>
<p>
We partner with organizations to assess their training needs, develop learning strategies, and implement training solutions that drive long-term success. Our consulting team provides insights into workforce development, performance improvement, and change management to help you maximize the return on your training investment.
</p>
<ul className="service-list">
<li>Training Needs Analysis</li>
<li>Learning Strategy Development</li>
<li>Performance Improvement Consulting</li>
<li>Change Management Support</li>
</ul>
        </div>
     
       </div>
       <div className="service-box">
       <div className="service-cont">
        <h3>5.B2B Summit </h3>
<p>
As a premier B2B event organizer, we have extensive experience in planning and executing successful events that bring together industry leaders, decision-makers, and key stakeholders. From conferences and trade shows to networking sessions and executive summits, we handle every aspect of your event to ensure it achieves your business objectives.
</p>
<ul className="service-list">
<li>Corporate Conferences & Summits</li>
<li>Trade Shows & Exhibitions</li>
<li>Business Networking Events</li>
<li>Product Launches & Corporate Events</li>
</ul>
        </div>
       <div className="service-img">
<div className="sr-patch-box">

</div>
<div className="sr-img-box">
<img src={ser5} alt="" />
</div>
</div>
     
   
       </div>
      </ServiceBoxes>
    </div>
  )
}
