import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import tl from '../assets/images/tr.png';
import { Link } from 'react-router-dom';
import './style.css';

const NavB = styled.nav`
  position: fixed;
  height: 70px;
  z-index: 20;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 20px;
  transition: all 0.5s ease;
  background: ${(props) => (props.isScrolled ? 'black' : 'transparent')};

  @media screen and (max-width: 600px) {
    padding-top: 0;
  }

  & img {
    width: 120px;
    margin-left: 50px;
    z-index: 10;

    @media screen and (max-width: 600px) {
      margin-left: 20px;
    }
  }

  & ul {
    width: 40%;
    list-style: none;
    color: white;
    display: flex;
    margin-right: 30px;
    justify-content: space-around;
    font-weight: 500;
    cursor: pointer;

    @media screen and (max-width: 600px) {
      display: ${(props) => (props.isOpen ? 'block' : 'none')};
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background: black;
      padding: 0;
      margin: 0px;
      padding-top: 70px;
      text-align: center;
      width: 100%;
      overflow: hidden;
    }
  }

  & ul li {
    padding: 10px 0;
  }

  & i {
    color: #fdb73e;
    transform: rotate(90deg);
    transition: all 0.3s ease;
  }

  & ul li:hover i {
    transform: rotate(180deg);
  }

  & ul li:hover .dropbox {
    display: block;
  }

  & .dropbox {
    background: white;
    z-index: 12;
    position: absolute;
    margin-top: 0.5vw;
    color: #000000ab;
    font-weight: 400;
    display: none;
  }

  & .drop {
    padding: 0.4vw 1.4vw;
    border-bottom: 1px solid #00000033;
  }

  & .drop:hover {
    background-color: #fdb73e;
    color: white;
  }

  & .drop:last-child {
    border: none;
  }
`;

const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  margin-right: 30px;

  @media screen and (max-width: 600px) {
    display: block;
    z-index: 1000;
  }

  & div {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 5px 0;
    transition: all 0.3s ease;
  }
`;

export default function NavBar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <NavB isScrolled={isScrolled} isOpen={isOpen}>
        <img src={tl} alt="trinity logo" />
        <Hamburger onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </Hamburger>
        <ul>
          <li>
            <Link to="/" onClick={() => setIsOpen(false)}>
              HOME
            </Link>
          </li>
          <li>
            <Link to="/about" onClick={() => setIsOpen(false)}>
              ABOUT US
            </Link>
          </li>
          <li>
            <Link to="/services" onClick={() => setIsOpen(false)}>
              SERVICES
            </Link>
          </li>
          <li>
            <Link to="/gallery" onClick={() => setIsOpen(false)}>
              GALLERY
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={() => setIsOpen(false)}>
              CONTACT US
            </Link>
          </li>
        </ul>
      </NavB>
    </div>
  );
}
